<template>
  <el-dialog
      :title="title"
      :visible.sync="createDialogVisible"
      width="800px"
      :close-on-click-modal="false"
      :append-to-body="true"
      v-drag-dialog
      @close="close">
    <div class="pageContainer">
      <el-form label-position="right"
               :model="formData"
               :rules="rules"
               ref="SupportRecordForm"
               label-width="100px"
               element-loading-background="rgba(0, 0, 0, 0.8)">
        <el-form-item label="企业名称" prop="companyName">
          <el-input v-model="formData.companyName" placeholder="请输入企业名称"></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人" prop="contact">
              <el-input v-model="formData.contact" placeholder="请输入联系人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人手机号" prop="mobile">
              <el-input v-model="formData.mobile" placeholder="请输入联系人手机号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="拍摄许可证" prop="certificate">
              <img :src="formData.certificate" @click="showImg(formData.certificate, '拍摄许可证')" class="smallImg" alt="">
              <file-upload-btn v-if="createDialogVisible" @uploadSuccess="handleUploadSuccessCertificate"></file-upload-btn>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公示表文件" prop="publicity">
              <img :src="formData.publicity" @click="showImg(formData.publicity, '公示表文件')" class="smallImg" alt="">
              <file-upload-btn v-if="createDialogVisible" @uploadSuccess="handleUploadSuccessPublicity"></file-upload-btn>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <el-row type="flex" justify="center">
        <el-button type="primary" @click="trySubmit" :loading="addingLoading">确认提交</el-button>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import FileUploadBtn from 'modules/components/fileUploadBtn/index.vue'
import { rules, resetSupportRecord, execute } from '../option'

export default {
  name: 'addOrEditSupportRecord',
  components: {
    FileUploadBtn
  },
  data() {
    return {
      optType: 'add',
      createDialogVisible: false,
      callback: null,
      formData: resetSupportRecord(),
      rules,
      addingLoading: false
    }
  },
  computed: {
    title() {
      return this.optType === 'add' ? '新增扶持记录' : '编辑扶持记录'
    }
  },
  methods: {
    ...mapActions(['saveSupportRecord', 'getSupportRecordById', 'updateSupportRecord']),
    show(options) {
      this.optType = options.optType
      this.callback = options.callback
      if (this.optType === 'edit') {
        this.tryGetSupportRecordById(options.detail.id)
        // this.formData = options.detail
      } else {
        this.formData = resetSupportRecord()
      }
      this.createDialogVisible = true
      this.$nextTick(() => {
        this.$refs.SupportRecordForm.clearValidate()
      })
    },
    close() {
      this.createDialogVisible = false
    },
    showImg(imgUrl, title) {
      execute('showImg', {
        imgUrl,
        title
      })
    },
    handleUploadSuccessCertificate(url) {
      this.formData.certificate = url
    },
    handleUploadSuccessPublicity(url) {
      this.formData.publicity = url
    },
    tryGetSupportRecordById(id) {
      this.getSupportRecordById(id).then(res => {
        this.formData = res || this.formData
      })
    },
    tryUpdateSupportRecord() {
      this.addingLoading = true
      this.updateSupportRecord(this.formData).then(() => {
        this.$message.success('操作成功')
        this.callback && this.callback()
        this.close()
      }).finally(() => {
        this.addingLoading = false
      })
    },
    trySubmit() {
      if (this.addingLoading) {
        return
      }
      this.$refs.SupportRecordForm.validate((valid) => {
        if(valid) {
          const api = this.optType === 'add' ? this.trySaveSupportRecord : this.tryUpdateSupportRecord
          api()
        }
      })
    },
    trySaveSupportRecord() {
      this.addingLoading = true
      this.saveSupportRecord(this.formData).then(() => {
        this.$message.success('提交成功')
        this.resetSupportRecord()
        this.callback && this.callback()
        this.close()
      }).finally(() => {
        this.addingLoading = false
      })
    },
    resetSupportRecord() {
      this.formData = resetSupportRecord()
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../scss/elementVar";

.pageContainer {
  padding: 20px;
}
.tagWrap {
  margin-bottom: 20px;
}
.uploadBtn {
  padding: 30px 0;
  width: 150px;
  height: 100px;
  box-sizing: border-box;
  text-align: center;
  color: $--color-info;
  border: 2px dashed $--color-info;
  opacity: 0.7;
  border-radius: 4px;
  transition: all linear 100ms;
}
.uploadBtn:hover {
  opacity: 1;
}
.uploadBtnIcon {
  font-weight: bold;
  font-size: 30px;
}
.uploadBtnText {
  line-height: 10px;
  font-size: 14px;
}
.imgContainer {
  float: left;
  margin: 0 10px 10px 0;
}
.smallImg{
  width: 40px;
}
</style>
